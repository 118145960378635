.app-container {
    min-height: 100vh;
    
    /* display: flex;
    flex-direction: column;
    align-items: center; */

    padding-top: 60px;
    margin: 0;

    /* background-color: #d5d5d5; */
    background-color: rgb(86, 102, 117);
    color: rgb(246, 247, 251);

    /* testing styles below */
    /* background-color: #f40000; */
}

.github-icon {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 65px;
    height: 65px;

    padding: 0;
    margin: 0;

    transition-duration: 300ms;
    transition-delay: 0ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;
}

.github-icon img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.github-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}