body {
    margin: 0;
    padding: 0;
}

.navbar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background-color: var(--charcoal);

    
    display: flex;
    align-items: center;
   

    z-index: 10;
}
  
.title {
    float: left;

    font-size: 24px;
    z-index: 10;
}
  
.nav-item {
    z-index: 10;
}

#nav-item a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    transition-duration: 0.3s;
    z-index: 10;
}

#nav-item a:hover {
    background-color: #111;
    z-index: 10;
}

/* .nav-search {
    padding: 0px;
    border: none;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 17px;
    border-radius: 10px;

    flex: 1;
    margin-right: 0px;
} */

.make-sticky {
    position: fixed;
    width: 100%;
    z-index: 10;
}

.searchBar {
    border-radius: 5px;
    border-style: solid;

    height: 25px;
    width: 100%;

    
    flex-grow: 1;
    padding-left: 10px; /* Add padding left of the text in the search bar. */
    padding-right: 10px; /* Add padding right of the text in the search bar. */
    
    z-index: 10;

    transition-duration: 300ms;
    transition-delay: 0ms;
}

.searchBar:hover {
    box-shadow: 1px 1px var(--charcoal);
    transform: scale(1.01);
}

.search-item {
    flex-grow: 1;

    margin-left: 15px;
    margin-right: 30px;
    z-index: 10;
}