.footer {
    background-color: var(--charcoal);
    color: whitesmoke;
    text-decoration: none;
    padding: 20px;
}

.footer-item {
    color: whitesmoke;
    text-decoration: none;
    transition-duration: 0ms;
}

.footer-item:hover {
    color: rgb(167, 167, 167);
}

.footer p {
    margin: 0;
}