.container2 {
    background-color: rgb(86, 102, 117);
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;

    /* background-image: url("https://i.ibb.co/zFRdcKh/background3.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; */

    color: rgb(40, 40, 40);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-text1 {
    text-align: center;
    text-shadow: 1px 1px 1px #000;
}

.mid-text {
    font-size: 35px;
    text-align: center;
    text-shadow: 1px 1px 1px #000;
}

.top-container-about {
    padding: 25px;
    margin: 0;
    /* border-style: solid; */
    border-radius: 10px;
    width: 35%;
    color: white;
    position: absolute;
    margin-top: 3%;
    text-align: left;

    border-style: solid;
    background-color: rgba(100, 120, 133, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}