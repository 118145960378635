.pageContainer {
    padding-top: 60px;
    padding-left: 100px;
    padding-right: 100px;
}

.user-container {
    border-style: solid;
    border-color: #3b4b5e;
    background-color: rgb(172, 190, 201);
    padding: 15px;
    border-radius: 10px;
    display: flex;
    margin-bottom: 40px;
}

.profileImage {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    box-shadow: 1px 1px 1px black;
}

.username {
    margin-left: 20px;
    font-size: 35px;
    text-shadow: 1px 1px 1px black;
}

.user-sets-container-title {
    margin-top: 50px;
    margin-bottom: 2px;
}

.username-sets {
    text-align: left;
    display: flex;
    padding: 0px;
    margin: 0;
}


.user-sets-container {
    border-style: solid;
    margin-top: 0px;
    height: 120px;
    overflow: auto;
    border-style: solid;
    background-color: #e0e0e0;
    padding: 10px;
    padding-top: 10px;
    border-radius: 10px;
}

#watewfwef {
    padding-bottom: 25px;
}