
.list {
    border-style: solid;
    border-color: var(--charcoal);
    border-radius: 10px;
    height: calc(85% - 250px);
    width: 60%;
    padding: 20px;
    margin: 10px;
    overflow-x: hidden;
    overflow-y: auto;

}

.listItem {
    border-style: solid;
    border-color: var(--charcoal);
    background-color: rgb(172, 190, 201);
    display: inline-block;
    padding-top: 40px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 40px;

    width: 50%;

    border-radius: 10px;

    transition-duration: 300ms;
    transition-delay: 0ms;
    transition-timing-function: ease;
    transition-property: transform;
}

.listItem:hover {
    transform: scale(1.03);
}

.listItem1 {
    margin-top: 8px;
    margin-left: 20%;
    margin-right: 8px;
    margin-bottom: 8px;
}

.listItem2 {
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 20%;
    margin-bottom: 8px;
}

.test {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button {
    padding-left: 15px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;

    margin: 5px;
    border-radius: 10px;
    font-weight: 500;

    transition-duration: 300ms;
    transition-delay: 0ms;
    transition-timing-function: ease;
    transition-property: background-color, transform;
}

.button:hover {
    background-color: rgb(203, 217, 225);
    transform: scale(1.05);
    cursor: pointer;
}

.deleteButton {
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;

    margin: 0;

    border-radius: 10px;

    transition-duration: 300ms;
    transition-delay: 0ms;
    transition-timing-function: ease;
    transition-property: background-color, transform;
}

.deleteButton:hover {
    background-color: rgb(203, 217, 225);
    transform: scale(1.05);
    cursor: pointer;
}

.container1 {
    background-color: rgb(86, 102, 117);
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;

    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container1 h2 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    padding: 7.25px;
}

.items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.new-input {
    padding: 10px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0;

    transition-duration: 300ms;
    transition-delay: 0ms;
    transition-timing-function: ease;
    transition-property: background-color, transform;
}

.new-input:hover {
    transform: scale(1.025);
}

.newstudylisttitle {
    color: white;
    text-shadow: 1px 1px 2px black;
}