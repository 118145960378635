/* 

    This is the code for the ScrollBar you see on LernIt.

*/

/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2e3c4a; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #41556b;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #475a6e;
}