.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: center;
}

.gridItem {
    background-color: #4CAF50;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    border: none;
}

.settingsButton {
    background-color: #008CBA;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    text-align: center;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
}

.settingstitle {
    text-shadow: 1px 1px 1px black;
}

.signoutbutton {
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;

    font-weight: bold;

    border: none;
    box-shadow: 3px 3px var(--charcoal);
    transition-duration: 0.25s;
}

.signoutbutton:hover {
    box-shadow: 2px 2px var(--charcoal);;
    background-color: rgb(228, 228, 228);
    transform: scale(1.05);
    cursor: pointer;
}