.container {
    background-color: rgb(47, 47, 47);
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
    background-image: url("https://i.ibb.co/zFRdcKh/background3.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.top-container {
    padding: 25px;
    margin: 0;
    /* border-style: solid; */
    border-radius: 10px;
    width: 35%;
    color: white;
    position: absolute;
    margin-top: 8%;
    margin-left: 10%;
    text-align: left;
}
.top-text {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 45px;
    /* offset-x | offset-y | blur-radius | color */
    text-shadow: 1px 1px 10px black;
    text-align: left;
}

.top-container p {
    padding: 0;
    margin: 0;
    font-weight: 300;
    margin-bottom: 40px;
    text-shadow: 1px 1px 10px black;
}

.btn-signup {
    padding: 18px 30px;
    margin: 0;
    border-style: solid;
    border-color: var(--charcoaldark);
    border-radius: 10px;
    width: 33%;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--charcoal);
    color: white;
    transition: all 0.5s;
    transition-delay: 0ms;
}

.btn-signup:hover {
    background-color: #19242f;
    color: rgb(203, 203, 203);
    cursor: pointer;
}

@media (max-width: 1000px) {
    .top-container {
        width: 70%;
        text-align: center;
    }
    .btn-signup {
        width: 70%;
    }
}

.list-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 25px;
}

.list-container-title {
    text-align: left;
    margin: 8px;
    text-shadow: 1.5px 1.5px 1px black;
}

.list-item-container {
    height: 150px;
    padding: 5px;
    padding-bottom: 15px;
    /* border-style: solid; */
    border-radius: 10px;
    overflow-x: scroll;
    overflow-y: hidden;

    display: flex;
    flex-direction: row;
}

.exampleBox {
    height: 100%;
    width: 260px;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--charcoal);
    margin: 5px;
    background-color: rgb(172, 190, 201);
    
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-delay: 0ms;
}

.exampleBox p {
    color: black;
    /* text-shadow: 0px 0px 2px black; */
    font-weight: 600;

}

.exampleBox:hover {
    background-color: rgb(162, 184, 208);
    transform: scale(1.05);
}

.box-a {   
    text-decoration: none;
    color: black;
}

.welcomebacktext {
    margin-bottom: 1em;
    text-shadow: 2px 2px 1px black;
}