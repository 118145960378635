.termsofservicecontainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 45px;
    padding-top: 50px;
    margin: 0;
}

.termsofservicecontainer p {
    text-align: left;
}

.tableofcontentsatags {
    text-align: left;
}