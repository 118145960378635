.form-left {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 50%;
    height: 100%;
    z-index: 1;
}

.form-left h1 {
    padding: 0;
    padding-top: 20px;
    /* padding-left: 50px;
    padding-top: 30px; */
    color: whitesmoke;
    text-shadow: 1px 1px 10px black;
    /* margin-right: auto; */
    margin-bottom: auto;
}

.form {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 50%;
    height: 100%;
    /* margin-left: auto; */
    /* background-color: rgba(255, 255, 255, 0.125); Transparent background */

    opacity: 0;
    animation-name: openthingsup;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; /* Ensure the final state of the animation persists */
}
@keyframes openthingsup {
    from {opacity: 0;}
    to {opacity: 1;}
  }

.signinpage {
    padding: 0;
    margin: 0;
    width: 100%;
    height: calc(100vh);
    background-image: url("https://i.ibb.co/zFRdcKh/background3.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    
    flex-direction: column; /* set to row if you want two sides */
    align-items: center;
    display: flex;
}
#form-holder {
    border-style: solid;
    margin: 0;
    margin-top: 20px;
    border: 2px solid;
    background-color: rgba(120, 120, 120, 0.1);
    display: flex;
    flex-direction: column;
    width: 270px;
    height: auto;
    border-radius: 5px;
    padding: 10px;
    color: white;

    /* -webkit-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75); */
    
}

.formTitle {
    color: white;
}

.form-input {
    margin-bottom: 1.2rem;
    box-shadow: none;
    background-color: transparent;
    border: 0;
    outline: 0;
    border-bottom: 2px solid #6e6a6a;
    color: white;
    font-size: 1.0rem;
    width: 80%;
    margin-left: auto; /* Center the button horizontally */
    margin-right: auto; /* Center the button horizontally */
}

.submit-button {
    font-weight: 500;

    padding: 0.7rem;
    margin-left: auto; /* Center the button horizontally */
    margin-right: auto; /* Center the button horizontally */
    width: 80%;
    font-size: 1.0rem;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
    /* border: 2px solid var(--charcoaldark);
    background-color: var(--charcoal); */
    background-color: rgb(241, 241, 241);
    border: 2px solid #000000;
    color: rgb(113, 113, 113);
}

.submit-button:hover {
    background-color: rgb(189, 189, 189);
    color: var(--charcoaldark);
}

.switcher {
    overflow: hidden;
    border: 2px solid #ffffff;
    /* backgrou nd-color: #f1f1f1; */
    width: max-content;
    border-radius: 5px;
    background-color: rgba(120, 120, 120, 0.1);

    /* -webkit-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75); */
    
    margin-top: 50px;
}

.switch {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 12px;
    transition: 0.3s;
    color: white;
}

.switch:hover {
    background-color: rgba(210, 210, 210, 0.65);
}

.switch.active {
    background-color: rgba(210, 210, 210, 0.5);
}

.login-with-google-btn {
    width: 80%;
    margin: auto;
    border: 2px solid #000000;
    margin-top: 10px;
}
.login-with-google-btn:hover {
    background-color: rgb(189, 189, 189);
    color: var(--charcoaldark);
}