.atagsinmysets {
    color: black;
    text-decoration: none;
    width: 100%;
}

.mysetsbox {
    background-color: rgb(172, 190, 201);
    border: 2px solid var(--charcoal);
    color: rgb(113, 113, 113);
}

.boxthingies {
    background-color: rgb(172, 190, 201);
    border: 2.5px solid var(--charcoal);
    border-radius: 8px;
    color: rgb(113, 113, 113);
    width: 100%;
    height: 3.5em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 4px;
    margin-bottom: 4px;
    color: black;

    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-delay: 0ms;
}

.boxthingies:hover {
    background-color: rgb(162, 184, 208);
    transform: scale(1.025);
}

.container {
    border: 3.5px solid var(--charcoal);
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;
    border-radius: 8px;

    padding: 16px;
    padding-left: 20px;
    padding-right: 20px;

    margin-bottom: 40px;
}

.container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.studyliststitle {
    margin-bottom: 1em;
    text-shadow: 2px 2px 1px black;
}